import { ITestimonialFields } from 'contentful-types'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import Testimonial, { TestimonialProps } from './Testimonial'

const ContentfulTestimonial = withContentful<ITestimonialFields, TestimonialProps>(Testimonial, {
  image: (props) => ({
    src: props.fields?.fullBleedImage?.fields?.file?.url,
    width: props.fields?.fullBleedImage?.fields?.file.details.image.width,
    height: props.fields?.fullBleedImage?.fields?.file.details.image.height,
  }),
})

export default ContentfulTestimonial
