import { Testimonial as AlliumTestimonial } from '@telus-uds/components-web'
import { contentfulLoader } from '../Image/contentfulLoader'
import { useContext } from 'react'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'

import Image, { type BasicImageProps } from '../Image/Image'

export type TestimonialProps = {
  testimonial: string
  testimonialStyle: 'large' | 'heading'
  author: string
  showDivider?: boolean
  additionalInfo: string
  image?: BasicImageProps
}

const Testimonial = ({
  testimonial,
  author,
  image,
  additionalInfo,
  showDivider,
  testimonialStyle,
}: TestimonialProps) => {
  const { isWebpSupported } = useContext(SiteBuilderContext)
  const {
    locale: { language },
  } = useContext(SiteBuilderContext)

  return (
    <AlliumTestimonial
      data-testid="Testimonial"
      testimonial={testimonial}
      title={author}
      additionalInfo={additionalInfo}
      showDivider={showDivider}
      testimonialStyle={testimonialStyle}
      copy={language}
      image={
        image?.src && (
          <Image
            // Perf Opt: twice the max width of the image
            src={contentfulLoader(isWebpSupported)({ src: image.src, width: 100 })}
            width={50}
            height={50}
            unoptimized
            alt={author}
          />
        )
      }
    />
  )
}

export default Testimonial
